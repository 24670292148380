@import "./index.css";

#root {
	position: relative;
}

.objectInput button {
	min-width: 70px;
}
.cookie {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	height: 200vh;
}
.cookieBlock {
	max-width: 50%;
	display: block;
	margin: auto auto;
	left: 25%;
	background-color: white;
	padding: 40px;
}
li button {
	display: flex;
	width: 32.5vw;
	border: 1px dashed black;
	background-color: white;
	margin-top: 5px;
	align-items: center;
}
.landenFilter {
	margin-top: 10px;
}

li button p:first-of-type {
	width: 25vw;
	padding-right: 10px;
}

li button p:nth-of-type(2) {
	width: 15vw;
}

li button p:nth-of-type(2) {
	width: 15vw;
}

li button p {
	text-align: left;
}

li {
	list-style: none;
}

a,
button {
	background-color: black;
	color: white;
	padding: 0.8em 3em;
	text-decoration: none;
	margin-right: 1em;
	overflow: hidden;
}

body {
	background-color: #83d0f5;
	font-family: "Source Sans Pro", sans-serif;
}
body:has(.homepageBlock) {
	background-color: #83d0f5;
}

p {
	line-height: 25px;
	color: black;
}

h1 {
	line-height: 45px;
	font-weight: bold;
}

#letterUI h3 {
	margin: 10px 0 1px 0;
	font-size: 1em;
}

#letterUI p {
	margin: 0;
}
.selectLanguage {
	display: block;
	background-color: black;
	height: 45px;
	display: flex;
	justify-content: flex-end;
}
.selectLanguage a {
	background-color: #2ddd62;
	color: black;
	border-radius: 0;
	font-weight: bold;
}
.selectLanguage a:hover {
	background-color: #f3e600;
}
.selectLanguage a:last-of-type {
	margin-right: 10vw;
}
.homepageBlock {
	display: flex;
	justify-content: center;
	width: 90%;
	margin: 0 auto;
	padding-bottom: 50px;
}

.homepageBlock img {
	padding-top: 10vh;
	width: 40vw;
}
.homepageLogos {
	display: flex;
	align-items: center;
	padding: 20px 200px;
}
.homepageLogos img {
	width: 220px;
	display: block;
	padding-right: 40px;
}

.blockText {
	width: 35%;
	padding: 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
}

.blockText h1 {
	font-size: 1.5em;
	margin-bottom: 10px;
	line-height: 35px;
}
.blockText h1:nth-of-type(2) {
	margin-top: 10px;
}

.blockText p,
.conditions p {
	margin-bottom: 10px;
	color: black;
	font-size: 1.1em;
}
.blockText b {
	font-weight: bold;
}

.blockText ol {
	margin-bottom: 10px;
	color: black;
	line-height: 25px;
	font-size: 1.1em;
}

.blockText img,
.logoWob {
	margin-bottom: 2em;
	width: 80px;
}
.nextButtons {
	margin-top: 30px;
	display: flex;
	align-items: center;
	max-width: 100%;
}
.nextButtons .start {
	width: 200px;
}
.nextButtons a {
	padding: 0;
	width: 150px;
	height: 30px;
	text-align: center;
	padding-top: 10px;
}

p.logo {
	color: black !important;
	margin-bottom: 0.5em;
	font-size: 2em;
	font-weight: 300;
}

p.header {
	text-align: center;
	color: black;
	font-size: 2em;
}

.popup {
	background-color: rgba(235, 94, 157, 0.7);
	margin-top: 15px;
}
.popupI img {
	cursor: pointer;
}

.letterComplete {
	position: absolute;
	width: 60%;
	right: 0;
	top: 0;
	z-index: 10;
	margin-top: 40px;
}

#letterUI {
	background-color: white;
	padding: 25px;
	box-shadow: -2px 0 0.9em grey;
}
.letterComplete .letterUIHeading {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.letterComplete .letterUILogos {
	margin-right: 20px;
	display: flex;
	align-items: center;
}
.letterComplete .letterUIHeading img {
	width: 100px;
	padding-right: 10px;
}
#letterUI .scribble,
.sectionWaarom .scribble {
	background-image: url("/letterUISingleLine.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	display: inline-block;
	margin-bottom: 0;
}
.letterUIAddress {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
#letterUI .letterUIAddress p {
	margin-bottom: 2px;
	display: inline-block;
}

.popup h1 {
	margin: 0px 0 15px 25px;
	font-size: 1.5em;
	padding-right: 25px;
}

.popup p {
	padding: 0 25px 25px 25px;
}

.popupI {
	background-color: rgba(250, 250, 250, 0);
	border: none;
	padding: 0;
	margin-left: 5px;
}
.popupI img {
	width: 20px;
	height: 20px;
	padding: 0;
	margin: 0;
}

.fontIcon {
	color: white;
}

.sectionWaarom {
	padding: 100px 0;
	width: 100%;
	margin: 0 auto;
	display: block;
	background-color: white;
}
.sectionWaarom a {
	background-color: #e8e8e8;
}
.sectionWaarom h1 {
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: -30px;
}

.conditions,
.textLongConditions {
	padding: 20px 0;
	width: 90%;
	margin: 0 auto;
	display: block;
	background-color: white;
}

.sectionWaarom img {
	display: block;
	margin: 0 auto;
}

.sectionBlock,
.sectionBlock2 {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 90%;
	margin: 0 auto;
}
.sectionLink {
	background-color: #e8e8e8;
	text-align: center;
	margin-top: 40px;
}
.sectionLink .sectionBlock {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px;
	background-image: url("/palmtree.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position-x: 18vw;
}
.sectionLink .sectionBlock a {
	background-color: #2ddd62;
	padding: 10px;
	margin-right: 10px;
	width: 10vw;
}
.sectionLink .sectionBlock div:nth-of-type(2) {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.sectionLink .sectionBlock div {
	margin: 0;
	background-color: rgba(255, 255, 255, 0.745);
}
.sectionLink .sectionBlock div:first-of-type {
	margin-bottom: 0;
}
.footer,
.footer .sectionBlock2,
.footer a {
	background-color: #e8e8e8;
}
.footer {
	padding: 50px 0;
}

.sectionBlock div {
	width: 30vw;
	padding: 40px;
	margin-bottom: 40px;
	background-color: #83d0f5;
}
.sectionBlock div a {
	background-color: #83d0f5;
	line-height: 25px;
	color: black;
	padding: 0;
	margin-right: 0;
	font-weight: bold;
}

.sectionBlock div h1,
.sectionBlock2 div h1,
.conditions .block2 h1 {
	font-size: 1.5em;
	margin-bottom: 0.2em;
}

.sectionBlock2 .projectTeam h2 {
	font-weight: bold;
	margin: 30px 0 10px;
}

.sectionBlock2 .projectTeam p {
	margin-bottom: 10px;
	font-size: 18px;
}

.footer .sectionBlock2 {
	width: 60%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}
.footer .sectionBlock2 .block1 {
	width: 40%;
	padding: 10px;
}
.footer .sectionBlock2 .block1 .spanLinks a {
	padding: 0;
	background-color: none;
	color: rgba(235, 94, 158, 0);
}
.sectionBlock2 .footerLogos {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.footerLogos img:first-child {
	height: 60px;
}
.footer h1 {
	text-align: center;
	font-size: 24px;
}
.sectionBlock2 .spanLinks img {
	margin: 20px 20px 20px 0;
}

.sectionBlock2 .spanLinks a {
	background-color: #e8e8e8;
	border-radius: 0;
	color: black;
	margin: 0;
	padding: 20px 20px 20px 0;
}

.conditions .block2,
.textLongConditions {
	padding: 40px;
	width: 60%;
}

.conditions .horizontalRule:nth-of-type(1) {
	margin-top: 100px;
}

.textLongConditions h2 {
	font-weight: bold;
	margin-top: 20px;
}

.conditions .block2 div p:first-of-type {
	margin: 40px 0;
}

.textLongConditions {
	margin: 0;
}

#authorities {
	max-height: 20em;
	overflow-y: scroll;
	max-width: 100%;
	overflow-x: hidden;
	margin-top: 20px;
}

.formLetter {
	padding: 40px;
	width: 33%;
}

.formLetter h2 {
	font-size: 2em;
	margin-bottom: 0.5em;
	font-weight: bold;
}

.formLetter h3 {
	font-size: 1.5em;
	font-style: italic;
	margin-top: 20px;
	padding-bottom: 3px;
}

.selectedAuthority h3 {
	font-style: normal;
	font-weight: bold;
}

.formLetter p {
	color: black;
	line-height: 25px;
}

.formLetter .lastOfType {
	margin-bottom: 40px;
}

.formLetter span {
	display: flex;
	align-items: center;
	margin: 15px 0;
}

.formLetter .selectedAuthority {
	border: 1px dashed grey;
	padding: 20px;
	margin-top: 20px;
}

.formInputs input {
	display: block;
}

.formInputs input,
input {
	padding: 2px;
	font-size: 1.1em;
}

form {
	margin-top: 10px;
	margin-bottom: 20px;
}

.extraPadding {
	padding: 0 0 40px 0;
}

.extraPaddingh2 {
	padding-top: 40px;
}

.buttonStyle {
	color: white;
	background-color: black;
	padding: 0.8em;
	border: 0;
	margin-right: 40px;
	margin-top: 20px;
	border-radius: 3px;
}

.buttonStyle2 {
	border: 1px dashed grey;
	color: black;
	background-color: #83d0f5;
	padding: 0.8em;
	margin-right: 16px;
	margin-bottom: 20px;
	margin-top: 10px;
	cursor: pointer;
	text-decoration: underline;
	font-weight: bold;
	font-size: 16px;
	width: 100%;
}

.subForm {
	margin-left: 2.3em;
}

.subsubForm {
	margin-left: 4.6em;
}

.subsubsubForm {
	margin-left: 6.9em;
}

/************************************************************

Checkbox styles

**************************************************************/

/* The container */
.container {
	display: block;
	position: relative;
	padding-left: 35px;

	cursor: pointer;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: white;
	border: 1px solid grey;
	border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

#error {
	color: red;
	margin-top: -15px;
	margin-bottom: 15px;
	font-weight: bold;
}

#smallError {
	margin-top: 0px;
	color: red;
	font-weight: bold;
}

.object button {
	margin: 0px;
}

.object p {
	padding-left: 5px;
}

.objectInput button {
	margin: 0;
	width: 100%;
	padding: 6.5px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	display: block;
}

#authorities .fontIcon {
	background-color: black;
	padding: 1.2em;
	margin-left: 5px;
}

.central {
	background-color: white;
	margin: 0 auto;
	width: 50%;
}

.plaintext {
	background-color: #83d0f5;
	color: black;
	padding: 0;
	margin: 0;
	font-weight: bold;
}
.logoSpoonGif {
	display: block;
	text-align: center;
}

.formLetter .objectInput {
	align-items: flex-end !important;
	width: 70%;
}

.object {
	align-items: center;
	justify-content: space-between;
	border: 1px dashed grey;
	margin-top: 10px;
	margin-bottom: 25px;
}

@media print {
	footer,
	#comments,
	#respond,
	.logoWob,
	.formLetter h2,
	.formLetter h1,
	button,
	a,
	.hidePrint {
		display: none;
	}

	.letterUI {
		width: 100vw;
		display: block;
		left: 0;
		margin: 0;
	}

	.letterComplete {
		width: 100%;
	}
	.tussenkopje {
		font-style: italic;
	}
}
@media only screen and (min-width: 300px) and (max-width: 1080px) {
	.blockText {
		width: 90%;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: nowrap;
	}

	.homepageBlock {
		display: flex;
		flex-wrap: wrap;
		width: 90%;
		margin: 0 auto;
	}

	.footer .sectionBlock2 .block1,
	.footer .sectionBlock2 {
		width: 90%;
	}
	.sectionBlock div {
		width: 90%;
	}
	.sectionBlock,
	.footer .sectionBlock2 {
		flex-wrap: wrap;
	}
	.extra_content {
		padding-bottom: 0;
	}
	.central {
		width: 80%;
	}
	.formLetter {
		padding: 20px;
		width: 90%;
	}
	a,
	button {
		padding: 0.8em 2em;
	}
	.letterComplete {
		display: none;
	}
	.nextButtons {
		flex-wrap: wrap;
	}
	.nextButtons .start {
		margin-bottom: 20px;
	}
	.homepageLogos {
		width: 100vw;
		padding: 20px 0 0 10vw;
		display: flex;
		flex-direction: row;
		justify-content: left;
	}
	.homepageLogos img {
		margin-right: 10px;
		padding: 0;
	}
	.homepageLogos img {
		max-width: 30vw;
	}
	.footerLogos {
		display: flex;
		flex-wrap: wrap;
	}
}
